<template>
  <div>
    <a-tooltip :mouseLeaveDelay="0" title="Type at least 3 characters to search for a user by his full name or email.">
      <a-select
        :getPopupContainer="trigger => trigger.parentElement"
        :status="field.isRequired && !isInputFilled ? 'warning' : ''"
        :allowClear="true"
        v-model:value="inputValue"
        :required="field.isRequired"
        show-search
        @search="handleSearch"
        :filterOption="() => true"
        :loading="loadingUsers"
        style="width: 100%"
        placeholder="Type at least 3 characters to find users ..."
        :size="inputSize"
        :auto-focus="autoFocus"
        :defaultOpen="openDropdown"
        :disabled="readOnly || (field.isReadOnlyAdd && addNew) || (field.isReadOnlyEdit && !addNew) || firstLoadActive"
        @blur="emitBlur"
        :open="selectOpen"
        @change="handleSelectChange"
        @dropdownVisibleChange="handleDropdownVisibleChange"
      >
        <a-select-option v-for="user in appSubtenantUsers" :key="user._id" :value="user._id">
          {{ user.firstName }} {{ user.lastName }} - {{ user.email }}
        </a-select-option>
      </a-select>
    </a-tooltip>
  </div>
</template>

<script>
import { userApi } from '@/apps/userManagement/api';
import InputMixin from './InputMixin';

export default {
  mixins: [InputMixin],
  data() {
    return {
      selectOpen: false,
      appSubtenantUsers: [],
      searchTimeout: null,
      loadingUsers: false,
    };
  },
  mounted() {
    if (this.inputValue && this.inputValue?.length) {
      this.fetchApplicationSubtenantUsers('', this.inputValue);
    }
  },
  methods: {
    async handleSearch(searchTerm) {
      this.searchedText = searchTerm;
      clearTimeout(this.searchTimeout);

      if (searchTerm.length >= 3) {
        this.searchTimeout = setTimeout(async () => {
          await this.fetchApplicationSubtenantUsers(searchTerm);
        }, 750);
      }
    },
    async fetchApplicationSubtenantUsers(searchTerm = '', userId = '') {
      this.loadingUsers = true;
      this.appSubtenantUsers = await userApi.getSubtenantUsersBySlugs(searchTerm, userId);
      this.loadingUsers = false;
    },

    handleSelectChange() {
      this.selectOpen = false;
    },
    handleDropdownVisibleChange(visible) {
      this.selectOpen = visible;
    },
  },
};
</script>
