<template>
  <a-radio-group v-if="!viewingMode" :size="inputSize" v-model:value="inputValue">
    <a-radio
      v-for="choice in field.structure.choices"
      :key="choice.value"
      :value="choice.value"
      :style="radioStyle"
      :disabled="readOnly || (field.isReadOnlyAdd && addNew) || (field.isReadOnlyEdit && !addNew) || firstLoadActive"
    >
      <span :class="`${field.isRequired && !isInputFilled ? 'text-warning' : ''}`">
        {{ choice.label || choice.value }}
      </span>
    </a-radio>
  </a-radio-group>

  <div v-else>
    <a-radio-group :size="inputSize" :value="rawValue">
      <a-radio v-for="choice in field.structure.choices" :key="choice.value" :value="choice.value" :style="radioStyle">
        {{ choice.label || choice.value }}
      </a-radio>
    </a-radio-group>
  </div>
</template>

<script>
import InputMixin from './InputMixin';

export default {
  mixins: [InputMixin],
  computed: {
    radioStyle() {
      return this.inputOptions.direction === 'vertical' ? 'margin-top: 10px; display: block;' : 'margin-top: 10px;';
    },
    selectedValueLabel() {
      if (this.inputValue) {
        const selectedChoice = this.field.structure.choices.find(c => c.value === this.inputValue);
        if (selectedChoice) {
          return selectedChoice.label;
        }
      }
      return '';
    },
  },
};
</script>
