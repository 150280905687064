<template>
  <a-form layout="vertical" :model="smtp" autocomplete="off">
    <!-- Sender Information -->
    <a-form-item
      class="formItem"
      label="From Email Address"
      name="email"
      :rules="[
        {
          required: true,
          message: 'Please input your email!',
        },
        {
          pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          message: 'Please enter a valid email address!',
        },
      ]"
    >
      <a-input v-model:value="smtp.email" placeholder="your-email@example.com" />
    </a-form-item>

    <a-form-item class="formItem" label="Display Name" name="name" :rules="[{ required: true, message: 'Please input your name!' }]">
      <a-input v-model:value="smtp.name" placeholder="Your Name" />
    </a-form-item>

    <!-- SMTP Settings -->
    <a-form-item class="formItem" label="SMTP Server" name="server" :rules="[{ required: true, message: 'Please input the SMTP server!' }]">
      <a-input v-model:value="smtp.server" placeholder="smtp.example.com" />
    </a-form-item>

    <a-form-item class="formItem" label="SMTP Port" name="port" :rules="[{ required: true, message: 'Please input the SMTP port!' }]">
      <a-input-number v-model:value="smtp.port" placeholder="465 or 587" style="width: 100%" />
    </a-form-item>

    <a-form-item class="formItem" label="Security Type" name="security" :rules="[{ required: true, message: 'Please select the security type!' }]">
      <a-select v-model:value="smtp.security" placeholder="Select a security type" @change="onSecurityTypeChange">
        <a-select-option value="ssl">SSL/TLS</a-select-option>
        <a-select-option value="starttls">STARTTLS</a-select-option>
        <a-select-option value="none">None</a-select-option>
      </a-select>
    </a-form-item>

    <!-- Conditionally display fields based on security type -->
    <a-form-item
      class="formItem"
      v-if="smtp.requiresAuth"
      label="SMTP Username"
      name="username"
      :rules="[{ required: true, message: 'Please input your SMTP username!' }]"
    >
      <a-input v-model:value="smtp.username" placeholder="SMTP username" />
    </a-form-item>

    <a-form-item
      class="formItem"
      v-if="smtp.requiresAuth"
      label="SMTP Password"
      name="password"
      :rules="[{ required: true, message: 'Please input your SMTP password!' }]"
    >
      <a-input-password v-model:value="smtp.password" placeholder="SMTP password" />
    </a-form-item>

    <!-- Action Buttons -->
    <a-form-item class="formItem">
      <a-space class="button-container">
        <a-button :loading="loading" type="default" @click="onTestConnection"> Send Test Email </a-button>
      </a-space>
      <a-alert :message="ruleMessage" v-if="ruleMessage" :type="messageType" style="margin-top: 10px" />
    </a-form-item>
  </a-form>
</template>

<script>
import emailApi from '@/apps/tenants/api/email.api';

export default {
  props: ['modelValue'],
  data() {
    return {
      loading: false,
      ruleMessage: null,
      messageType: 'succes',
      smtp: {
        email: '',
        name: '',
        server: '',
        port: 465,
        security: 'ssl',
        username: '',
        password: '',
        requiresAuth: true, // Default requires authentication
      },
    };
  },
  watch: {
    smtp: {
      deep: true,
      handler(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
  mounted() {
    if (this.modelValue) {
      this.smtp = this.modelValue;
    }
  },
  methods: {
    onSecurityTypeChange(value) {
      // If the selected security type is "none", disable authentication fields
      this.smtp.requiresAuth = value !== 'none';
      if (!this.smtp.requiresAuth) {
        this.smtp.smtpUsername = '';
        this.smtp.smtpPassword = '';
      }
    },
    async onTestConnection() {
      this.loading = true;
      const testMessage = await emailApi.postSmtpTest(this.smtp);
      if (testMessage) {
        this.ruleMessage = testMessage.error;
        this.messageType = 'error';
      } else {
        this.ruleMessage = 'Email send !';
        this.messageType = 'success';
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.formItem {
  margin-bottom: 10px;
}
.button-container {
  display: flex;
  justify-content: center; /* Centers the button horizontally */
}
</style>
