<template>
  <div v-if="!viewingMode">
    <a-input-number
      style="width: 100%"
      :status="field.isRequired && !isInputFilled ? 'warning' : ''"
      v-model:value="inputValue"
      :readOnly="readOnly"
      :min="typeof structureOptions.minValue === 'number' ? structureOptions.minValue : -Infinity"
      :max="typeof structureOptions.minValue === 'number' ? structureOptions.maxValue : +Infinity"
      :formatter="
        (value, { userTyping, input }) => {
          if (userTyping) {
            return input;
          }

          if (field.formatNumber) {
            return formatNumber(value, this.subtenantData?.fieldsNumberFormat, structureOptions.decimalPlaces);
          } else {
            let val = null;
            if (structureOptions.decimalPlaces !== null && structureOptions.decimalPlaces !== 0 && value) {
              val = value.match('^-?\\d{0,12}\\.?\\d{0,' + structureOptions.decimalPlaces + '}');
              if (!onFocus && structureOptions?.fixedDecimals) {
                if (Array.isArray(val)) {
                  val.forEach((v, index) => {
                    val[index] = parseFloat(v).toFixed(structureOptions.decimalPlaces);
                  });
                } else {
                  val = parseFloat(val).toFixed(structureOptions.decimalPlaces);
                }
              }
              return val;
            } else {
              return value.match('^-?\\d{0,12}');
            }
          }
        }
      "
      :parser="
        value => {
          if (field.formatNumber) {
            return unformatNumber(value, this.subtenantData?.fieldsNumberFormat, structureOptions.decimalPlaces);
          }
          return value;
        }
      "
      :placeholder="inputPlaceholder"
      :required="field.isRequired"
      :controls="localArrowControl"
      :size="inputSize"
      :auto-focus="autoFocus"
      :disabled="
        field.logic.calculation.isEnabled || readOnly || (field.isReadOnlyAdd && addNew) || (field.isReadOnlyEdit && !addNew) || firstLoadActive
      "
      @change="onFocus = true"
      @blur="
        e => {
          onFocus = false;
          if (inputValue && structureOptions?.fixedDecimals && structureOptions.decimalPlaces !== 0 && !field.formatNumber) {
            inputValue = parseFloat(inputValue).toFixed(structureOptions.decimalPlaces);
          }
        }
      "
      @pressEnter="e => $emit('pressEnter', e)"
    />
  </div>

  <div v-else>
    <a-input-number
      style="width: 100%"
      v-model:value="rawValue"
      :readOnly="readOnly"
      :placeholder="inputPlaceholder"
      :required="field.isRequired"
      :size="inputSize"
      :auto-focus="autoFocus"
      :disabled="true"
      :controls="localArrowControl"
      :formatter="
        value => {
          if (field.formatNumber) {
            return formatNumber(parseFloat(value), this.subtenantData?.fieldsNumberFormat, structureOptions.decimalPlaces);
          }
          return value;
        }
      "
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { SlugGetters } from '@/slug.store';
import { TenantsGetters } from '@tenants/shared/tenants.store';
import { formatNumber, unformatNumber } from '@/core/utils/number-manipulation';
import InputMixin from './InputMixin';

export default {
  mixins: [InputMixin],
  emits: ['pressEnter'],
  props: ['arrowControl'],
  data() {
    return {
      onFocus: false,
    };
  },
  computed: {
    localArrowControl() {
      return this.arrowControl ?? true;
    },
    subtenantData() {
      let subtenantData = [];
      if (SlugGetters.getSubtenantSlug()) {
        subtenantData = TenantsGetters.getSubtenantBySlugs(SlugGetters.getTenantSlug(), SlugGetters.getAppSlug(), SlugGetters.getSubtenantSlug());
      }
      return subtenantData;
    },
  },
  methods: {
    isPropValueValid() {
      return this.value === null || _.isFinite(Number(this.value));
    },
    isInputValueValid() {
      return this.inputValue === null || _.isFinite(Number(this.inputValue));
    },
    countDecimals(value) {
      if (Math.floor(value) === value) {
        return 0;
      }
      return value.toString().split('.')[1].length || 0;
    },
    isFloat(n) {
      return parseFloat(n.match(/^-?\d*(\.\d+)?$/)) > 0;
    },
    formatNumber,
    unformatNumber,
  },
  watch: {
    inputValue: {
      deep: true,
      handler(value, oldValue) {
        if (value !== null && value !== '') {
          if (this.isFloat(value.toString()) && value.toString().includes('.') && !this.field.formatNumber) {
            if (this.countDecimals(value) > this.structureOptions.decimalPlaces && oldValue !== null) {
              this.inputValue = parseFloat(this.inputValue.toString());
            }
          }
        }
      },
    },
  },
  mounted() {
    if (this.structureOptions.defaultValue && !this.inputValue) {
      this.inputValue = this.structureOptions.defaultValue;
    }
    this.emitInput();
  },
};
</script>
<style scoped>
.not-filled {
  border-color: red;
}
</style>
