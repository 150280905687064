<template>
  <a-tabs type="card" v-if="user">
    <a-tab-pane :key="1" tab="General" class="tabsStyle" v-if="!modalObject._id || !isUserSubtenantManager()">
      <p>
        <a-input v-model:value="modalObject.name" placeholder="Name" />
        <span style="font-weight: 500; margin-left: 5px; color: red" v-if="!isSubtenantNameUnique"> Name is already in use. </span>
      </p>
      <p>
        <a-textarea v-model:value="modalObject.description" placeholder="Descriere" data-cy="inputTenantDescription" />
      </p>
      <label>Blueprint date {{ modalObject.name }}:</label>
      <BlueprintSelect
        v-model="modalObject.blueprintId"
        style="min-width: 100%"
        @update:modelValue="
          newValue => {
            modalObject.blueprintId = newValue;
          }
        "
      />
      <div style="margin-top: 20px">
        <label>Two Factor Authentication is mandatory for users:</label>
        <div>
          <a-switch v-model:checked="modalObject.enabled2FA" checked-children="Enabled 2FA" un-checked-children="Disabled 2FA"></a-switch>
        </div>
      </div>
      <label style="margin-top: 20px">
        <span class="subtenant-logo">
          App logo:
          <help-icon-popover
            title="Size and image type"
            content="Only jpg/png files with (120px x 55px) resolution. (Preferably transparent png image for better display)"
          />
        </span>
      </label>
      <p>
        <a-upload
          :file-list="[]"
          :customRequest="handleUpload"
          list-type="picture-card"
          class="avatar-uploader ant-subtenant-logo-upload"
          :show-upload-list="false"
          :before-upload="beforeUpload"
        >
          <div>
            <img style="width: 120px; height: 55px" v-if="modalObject.logo" :src="modalObject.logo" alt="avatar" />
            <div v-else>
              <LoadingOutlined v-if="uploadLoading" />
              <PlusOutlined v-else />
              <div class="ant-upload-text">Upload</div>
            </div>
          </div>
        </a-upload>
        <a-button v-if="modalObject.logo" size="small" class="ml-3 subtenant-delete-logo" @click="handleRemove()">
          <DeleteOutlined />
        </a-button>
      </p>

      <p>Click on the element below to copy the code and insert it in a template to add the logo where is desired:</p>
      <a-tooltip :mouseLeaveDelay="0" title="Click to copy">
        <a-tag style="cursor: pointer" @click="copyEmbedCodeToClipboard">
          {{ logoEmbedCode }}
        </a-tag>
      </a-tooltip>
    </a-tab-pane>
    <a-tab-pane :key="2" tab="Email" v-if="modal.type === 'subtenant'" class="tabsStyle">
      <SMTP v-model="modalObject.emailSMTP"></SMTP>
    </a-tab-pane>
  </a-tabs>
  <div style="padding-top: 10px">
    <a-row>
      <a-col :span="12">
        <a-button type="primary" :loading="loading" @click="processForm">
          <template v-if="modal.edit"><EditOutlined /> Edit</template>
          <template v-else><PlusOutlined /> Create</template>
        </a-button>
      </a-col>
      <a-col :span="12" style="text-align: right">
        <a-button key="back" @click="closeModal"> Cancel </a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { TenantsActions } from '@tenants/shared/tenants.store';
import { BlueprintSelect } from '@dataSystem/components/BlueprintSelect';
import { EditOutlined, PlusOutlined, LoadingOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { logoUpload } from '@dataSystem/api/subtenantLogoUpload.api';
import { SMTP } from '@/apps/tenants/views/TenantList/components/forms/email';
import { UserGetters } from '@/apps/userManagement/user.store';

export default {
  props: ['users', 'tenantId', 'appId', 'modal'],
  emits: ['close', 'closeModal', 'modalUpdate'],
  components: {
    BlueprintSelect,
    EditOutlined,
    PlusOutlined,
    LoadingOutlined,
    DeleteOutlined,
    SMTP,
  },
  computed: {
    user: UserGetters.getUser,
  },
  data() {
    return {
      loading: false,
      isLoading: false,
      selectedOwnerRoleId: null,
      userRoles: [],
      instance: null,
      instanceList: null,
      instanceId: null,
      selectdUsers: [],
      modalObject: {
        name: '',
        blueprintId: null,
      },
      logoEmbedCode: `<SubtenantLogo style="width: 120px; heigth: 55px;" />`,
      isSubtenantNameUnique: true,
      uploadLoading: false,
    };
  },
  mounted() {
    if (this.modal.edit) {
      this.modalObject.name = this.modal.editData.name;
      this.modalObject.description = this.modal.editData.description;
      this.modalObject._id = this.modal.editData._id;
      this.modalObject.blueprintId = this.modal.editData.blueprintId ?? null;
      this.modalObject.emailSMTP = this.modal.editData.emailSMTP ?? null;
      this.modalObject.enabled2FA = this.modal.editData.enabled2FA ?? false;
      this.modalObject.logo = this.modal.editData.logo ?? '';
    } else {
      this.modalObject.name = null;
      this.modalObject.description = null;
      this.modalObject.blueprintId = null;
      this.modalObject.emailSMTP = null;
      this.modalObject.enabled2FA = false;
      this.modalObject.logo = '';
    }
  },
  methods: {
    copyEmbedCodeToClipboard() {
      navigator.clipboard.writeText(this.logoEmbedCode);

      notification.success({
        message: 'Saved',
        description: `Embed code copied to clipboard with success.`,
      });
    },
    isUserSubtenantManager() {
      return this.modal.editData?.managerUserIdList?.indexOf(this.user._id) !== -1;
    },
    close() {
      this.$emit('close');
    },
    closeModal() {
      this.$emit('closeModal');
    },

    async processForm() {
      const modalUpdate = this.modal;
      if (!this.modalObject.name || this.modalObject.name.trim().length === 0) {
        return;
      }
      this.loading = true;

      console.log(this.tenantId, this.appId, this.modalObject._id, this.modalObject);

      try {
        if (this.modal.type === 'subtenant') {
          if (this.modal.edit) {
            await TenantsActions.editOneSubtenant(this.tenantId, this.appId, this.modalObject._id, this.modalObject);
          } else {
            const newSubtenant = await TenantsActions.createOneSubtenant(this.tenantId, this.appId, this.modalObject);
            this.modalObject._id = newSubtenant?._id?.toString() ?? null;
            modalUpdate.editData.slug = newSubtenant?.slug;
            modalUpdate.edit = true;
          }
        }
        if (this.modal.type === 'application') {
          if (this.modal.edit) {
            await TenantsActions.editOneApplication(this.tenantId, this.modalObject._id, this.modalObject);
          } else {
            const newAplication = await TenantsActions.createOneApplication(this.tenantId, this.modalObject);
            this.modalObject._id = newAplication?._id?.toString() ?? null;
            modalUpdate.editData.slug = newAplication?.slug ?? null;
            modalUpdate.edit = true;
          }
        }
        if (this.modal.type === 'tenant') {
          if (this.modal.edit) {
            await TenantsActions.editOneTenant(this.modalObject._id, {
              ...this.modalObject,
            });
          } else {
            const newTenant = await TenantsActions.createOneTenant({
              ...this.modalObject,
            });
            this.modalObject._id = newTenant?._id?.toString() ?? null;
            modalUpdate.editData.slug = newTenant?.slug;
            modalUpdate.edit = true;
          }
        }
        this.$emit('modalUpdate', modalUpdate);

        this.closeModal();
      } catch (error) {
        this.isSubtenantNameUnique = false;
      }
      this.loading = false;
    },
    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (isJpgOrPng === false) {
        notification.error({
          message: 'Failed',
          description: 'You can only upload JPG/PNG file!',
        });
      }
      return isJpgOrPng;
    },
    async handleUpload({ file }) {
      this.uploadLoading = true;
      const response = await logoUpload(file);

      if (response.error === true) {
        notification.error({
          message: 'Failed',
          description: response.message,
        });
        this.uploadLoading = false;
      } else {
        this.modalObject.logo = response.message.base64img;
        this.uploadLoading = false;
      }
    },
    handleRemove() {
      this.modalObject.logo = '';
    },
  },
};
</script>
<style scoped>
.tabsStyle {
  padding: 0px 10px 10px 10px;
}
.subtenant-logo {
  cursor: pointer;
}
.ant-subtenant-logo-upload {
  width: auto;
  vertical-align: middle;
}
.subtenant-delete-logo {
  vertical-align: middle;
}
</style>
