export function unformatNumber(input, format = '', decimals = 0) {
  let number = null;
  let isoFormat = 'ro-RO';
  if (format) {
    isoFormat = format;
  }

  if (input && !Number.isNaN(parseFloat(input))) {
    if (isoFormat && isoFormat === 'ro-RO') {
      number = input.toString().replace(/\./g, '').replace(/,/g, '.');
    } else if (isoFormat && isoFormat === 'en-US') {
      number = input.toString().replace(/,/g, '');
    } else {
      number = input.toString().replace(/,/g, '');
    }
  }

  if (number === '' || number === null) {
    return null;
  }

  if (decimals && decimals !== 0) {
    return parseFloat(number);
  }
  return Math.trunc(parseFloat(number));
}

export function formatNumber(number, format = '', decimals = 0, style = '') {
  const options = {};
  let numFormat = null;
  let isoFormat = 'ro-RO';

  if (number !== '' || numFormat !== null) {
    if (decimals && decimals !== 0) {
      numFormat = parseFloat(number);
    } else {
      numFormat = Math.trunc(parseFloat(number));
    }
  }
  if (format) {
    isoFormat = format;
  }

  if (decimals && decimals !== 0) {
    options.style = 'decimal';
    options.minimumFractionDigits = decimals;
    options.maximumFractionDigits = decimals;
  }

  if (style.type && style.type === 'currency') {
    options.style = style.type;
    options.currency = style.currency;
  }

  if (style.type && style.type === 'percent') {
    options.style = style.type;
    options.minimumFractionDigits = style.fractionDigits;
    options.maximumFractionDigits = style.fractionDigits;
  }

  if (numFormat === '' || numFormat === null) {
    return null;
  }
  return new Intl.NumberFormat(isoFormat, options).format(numFormat);
}
