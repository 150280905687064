<template>
  <div>
    <template v-if="isGroup">
      <div>
        <component
          class="data-manipulation-element"
          :status="field.isRequired && !isInputFilled ? 'warning' : ''"
          :key="indexValue"
          :is="elementInputComponent"
          :value="valueByIndex"
          :readOnly="readOnly"
          :disabled="(field.isReadOnlyAdd && addNew) || (field.isReadOnlyEdit && !addNew) || firstLoadActive"
          @update="value => onInput(indexValue, value)"
          :field="field"
          :structureRuleset="elementStructure.ruleset"
          :structureOptions="elementStructure.options"
          :inputRuleset="elementInput.ruleset"
          :inputOptions="elementInput.options"
          :inputSize="inputSize"
          :inputPlaceholder="inputPlaceholder"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
        />
      </div>
    </template>
    <template v-else>
      <div v-for="(elem, index) in inputValue" :key="index" class="field-array-container" style="margin-bottom: 5px">
        <component
          class="data-manipulation-element"
          :key="index"
          :is="elementInputComponent"
          :value="elem"
          :readOnly="readOnly"
          :disabled="(field.isReadOnlyAdd && addNew) || (field.isReadOnlyEdit && !addNew) || firstLoadActive"
          @update="
            value => {
              onInput(index, value);
            }
          "
          :field="field"
          :structureRuleset="elementStructure.ruleset"
          :structureOptions="elementStructure.options"
          :inputRuleset="elementInput.ruleset"
          :inputOptions="elementInput.options"
          :inputSize="inputSize"
          :inputPlaceholder="inputPlaceholder"
          ref="fieldComponents"
          :viewingMode="viewingMode"
        />
        <a-button v-if="!viewingMode" @click="addElement(index)" size="small" style="margin-left: 10px; font-weight: 500"> + </a-button>
        <a-button @click="removeElement(index)" v-if="inputValue.length > 1 && !viewingMode" size="small" style="margin-left: 10px; font-weight: 500">
          -
        </a-button>
      </div>
    </template>
  </div>
</template>

<script>
import InputMixin from './InputMixin';

import InputDate from './InputDate.vue';
import InputText from './InputText.vue';
import InputNumber from './InputNumber.vue';
import InputReference from './InputReference.vue';
import InputUser from './InputUser.vue';

export default {
  props: ['indexGroups', 'indexValue'],
  emits: ['focus', 'blur'],
  mixins: [InputMixin],
  computed: {
    valueByIndex() {
      const value = (this.inputValue && this.inputValue[this.indexValue]) ?? null;
      return value ?? undefined;
    },
    elementStructure() {
      return this.field?.structure?.elementStructure;
    },
    widget() {
      return this.field?.widget;
    },
    elementInput() {
      return this.field?.input?.elementInput;
    },
    elementInputComponent() {
      if (this.widget.type === 'user_select') {
        return InputUser;
      }
      if (this.elementStructure.type === 'string') {
        return InputText;
      }
      if (this.elementStructure.type === 'number') {
        return InputNumber;
      }
      if (this.elementStructure.type === 'date') {
        return InputDate;
      }
      if (this.elementStructure.type === 'reference') {
        return InputReference;
      }
      return null;
    },
  },
  methods: {
    async setValueAndFetchInstances(instanceId) {
      if (!Array.isArray(this.inputValue)) {
        this.inputValue = null;
      }

      if (this.inputValue.length === 1 && this.inputValue[0] === null) {
        this.inputValue[0] = instanceId;
        this.$refs.fieldComponents[0].fetchInstances();
      } else {
        this.inputValue.unshift(instanceId);
      }

      if (this.inputValue[0] !== null) {
        await new Promise(
          this.$nextTick(() => {
            this.inputValue.forEach((_id, index) => {
              this.$refs.fieldComponents[index].fetchInstances();
            });
          })
        );
      }
    },
    getDefaultValue() {
      return [undefined];
    },
    getDefaultValueFromFieldSettings() {},
    onInput(index, value) {
      if (value) {
        this.inputValue[index] = value;
      } else if (this.inputValue && this.inputValue[index]) {
        this.inputValue[index] = undefined;
      }
    },
    addElement(index) {
      this.inputValue.splice(index + 1, 0, undefined);
    },
    removeElement(index) {
      this.inputValue.splice(index, 1);
    },
  },
};
</script>
