<template>
  <div>
    <a-checkbox style="margin: 15px 0" v-model:checked="input.isEnabled">
      Enable Filtering
      <help-icon-popover title="Fitlering" content="Filter data from Database" style="float: right; top: 1px" />
    </a-checkbox>

    <div style="padding: 0 27.5px" v-if="input.isEnabled">
      <span style="margin: 15px 0">
        Connect with reference
        <help-icon-popover title="Connect with reference _ID" content="Connect with reference _ID" />
        <a-tooltip title="Add condition" v-if="input.isEnabled">
          <a-button @click="onClickAddConditionReference" style="right: 0; position: absolute"><PlusOutlined /></a-button>
        </a-tooltip>
      </span>

      <div style="padding: 0 27.5px" v-if="input.isEnabled">
        <div v-for="(condition, index) in input.references" :key="index" style="margin: 15px 0">
          <span style="margin-right: 15px; font-weight: 500"> {{ index + 1 }}. </span>
          <a-select
            placeholder="Select field"
            style="min-width: 200px; margin-right: 15px"
            v-model:value="condition.fieldId"
            @change="onChangeReferenceField"
          >
            <a-select-option v-for="field in fieldsBeforeThis" :key="field._id" :value="field._id">
              (ID: {{ field.successionIndex }}) {{ field.label }}
            </a-select-option>
          </a-select>

          <a-tooltip title="Remove condition">
            <a-button @click="onClickRemoveConditionReference" style="margin-left: 5px"><MinusOutlined /> </a-button>
          </a-tooltip>
        </div>
      </div>
    </div>

    <div style="padding: 0 27.5px; margin-top: 15px" v-if="input.isEnabled">
      <span style="margin: 15px 0">
        Connect with fields
        <help-icon-popover title="Connect with field" content="Check if a field contains a static value" />
      </span>
      <a-tooltip title="Add condition" v-if="input.isEnabled">
        <a-button @click="onClickAddCondition" style="right: 0; position: absolute"> <PlusOutlined /></a-button>
      </a-tooltip>
      <div style="padding: 0 27.5px" v-if="input.isEnabled">
        <div v-for="(condition, index) in input.conditions" :key="index" style="margin: 15px 0">
          <span style="margin-right: 15px; font-weight: 500"> {{ index + 1 }}. </span>
          <a-select placeholder="Select field" style="min-width: 350px; margin-right: 15px" v-model:value="condition.fieldId" @change="onChangeField">
            <a-select-option v-for="field in referenceFields" :key="field._id" :value="field._id">
              (ID: {{ field.successionIndex }}) {{ field.label }} ({{ field.structure.type }})
            </a-select-option>
          </a-select>
          <a-select v-model:value="condition.comparator" style="min-width: 100px; margin-right: 15px" default-value="equal">
            <a-select-option value="EQUAL">is equal to</a-select-option>
            <a-select-option value="NOT_EQUAL">is not equal to</a-select-option>
          </a-select>
          <ExpectedValueInput
            :fieldId="condition.fieldId"
            :fieldType="condition.fieldType"
            :condition="condition"
            style="margin-top: 10px; padding-right: 15px; display: inline-block; width: 200px"
            @expectedValue="setCondition($event, condition)"
          />
          <a-tooltip title="Remove condition">
            <a-button @click="onClickRemoveCondition" style="margin-left: 5px"><MinusOutlined /> </a-button>
          </a-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { blueprintApi } from '@dataSystem/api';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons-vue';
import FieldInputOptionsMixin from '../FieldInputOptionsMixin';

import ExpectedValueInput from './ExpectedValueInput.vue';
import { FormBuilderGetters } from '../../../formBuilder.store';

export default {
  mixins: [FieldInputOptionsMixin],
  fieldInputOptions: {
    path: 'logic.filter',
    keys: ['isEnabled', 'action', 'mode', 'conditions', 'references'],
  },
  data() {
    return {
      referenceCondition: false,
      localCondition: false,
      referencedBlueprint: [],
      referenceFields: [],
      fieldsBefore: [],
      fieldStructure: null,
    };
  },
  components: {
    ExpectedValueInput,
    MinusOutlined,
    PlusOutlined,
  },
  async mounted() {
    this.fieldStructure = this.fieldInput?.structure?.ruleset?.blueprintId
      ? this.fieldInput?.structure // field
      : this.fieldInput?.structure?.elementStructure; // group field
    this.referencedBlueprint = await blueprintApi.getAllWithFields([
      this.fieldStructure?.ruleset?.blueprintId, // group field
    ]);
    // console.log(this.referencedBlueprint);
    this.referenceFields = await this.referencedBlueprint[0].fields;
    this.fieldsBefore = FormBuilderGetters.getFieldsBefore(this.fieldId);
    [this.field] = this.referenceFields;
  },
  computed: {
    fieldsBeforeThis() {
      const fieldsReferences = [];
      if (this.fieldStructure) {
        FormBuilderGetters.getFieldsBefore(this.fieldId).forEach(obj => {
          if (obj.structure.type === 'reference') {
            this.fieldStructure.ruleset?.connections.forEach(conn => {
              if (FormBuilderGetters.getFieldInput(obj._id).structure.ruleset.blueprintId === conn.associatedBlueprintId) {
                fieldsReferences.push(obj);
              }
            });
          }
        });
      }
      return fieldsReferences;
    },
  },
  methods: {
    onChangeField(value) {
      let structureType = null;
      let blueprintId = null;
      this.referenceFields.forEach(ref => {
        if (ref._id === value) {
          structureType = ref.structure.type;
          blueprintId = ref.blueprintId;
        }
      });
      this.input.conditions.forEach(condition => {
        if (condition.fieldId === value) {
          condition.fieldType = structureType;
          condition.blueprintId = blueprintId;
        }
      });
    },
    onChangeReferenceField(value) {
      const selectedField = this.fieldsBeforeThis.find(f => f._id === value);
      if (selectedField) {
        const selFieldBpId = selectedField.structure.ruleset.blueprintId;
        this.input.references.forEach(ref => {
          if (ref.fieldId === value) {
            ref.fieldType = this.fieldStructure.type;
            ref.blueprintId = this.fieldStructure.ruleset.blueprintId;
            ref.connectionFieldId = this.fieldStructure.ruleset.connections.find(f => f.associatedBlueprintId === selFieldBpId)?.connectionFieldId;
          }
        });
      }
    },
    onClickAddConditionReference() {
      if (!this.input.references) {
        this.input.references = [];
      }
      this.input.references.push({
        fieldId: null,
        comparator: 'EQUAL',
        fieldType: null,
        expectedValue: null,
      });
    },
    onClickRemoveConditionReference(index) {
      this.input.references.splice(index, 1);
    },
    onClickAddCondition() {
      if (!this.input.conditions) {
        this.input.conditions = [];
      }
      this.input.conditions.push({
        fieldId: null,
        comparator: 'EQUAL',
        fieldType: null,
        expectedValue: null,
      });
    },
    onClickRemoveCondition(index) {
      this.input.conditions.splice(index, 1);
    },
    setCondition(conditionObj, condition) {
      // FormBuilderGetters.initBuilder("blueprintId");
      condition.expectedValue = conditionObj.value;
      if (conditionObj.subtenantSlug) {
        condition.subtenantSlug = conditionObj.subtenantSlug;
      }
    },
  },
};
</script>

<style></style>
