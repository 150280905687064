<template>
  <div>
    <div v-if="fieldStructureType === 'reference'">
      <!--      <a-select
        placeholder="Select subtenant"
        v-model:value="subtenantSlug"
        style="width: 100%; margin-bottom: 5px"
        :options="subtenantOptions"
        @change="
          getReferenceInstanceOptions();
          emitSelection();
        "
      >
      </a-select>-->
      <a-select
        v-model:value="inputValue"
        :options="referenceOptions"
        style="width: 100%"
        :loading="referenceOptionsLoading"
        @change="emitSelection"
        show-search
        :filter-option="filterOption"
      ></a-select>
    </div>

    <div v-else>
      <a-input v-model:value="inputValue" @input="emitSelection" />
    </div>
  </div>
</template>

<script>
// TODO: Implement Input for Array and Json
// TODO: Implement logic for field.choices
// TODO: Decide if this component is needed or if FieldWidget can be used instead
import { TenantsGetters } from '@tenants/shared/tenants.store';
import blueprintApi from '@dataSystem/api/blueprint.api';
import subtenantApi from '@tenants/api/subtenant.api';
import { fieldApi, instanceApi } from '@/apps/dataSystem/api';
import { FormBuilderGetters } from '../../../formBuilder.store';

export default {
  props: ['fieldId', 'condition', 'fieldType', 'blueprintId'],
  emits: ['update:modelValue', 'expectedValue'],
  data() {
    return {
      inputValue: null,
      subtenantSlug: undefined,
      subtenantOptions: [],
      selectedFieldType: null,

      referenceOptions: [],
      referenceOptionsLoading: false,
      field: null,
    };
  },
  computed: {
    fieldStructureType() {
      if (!this.field) {
        return 'not_selected';
      }
      return this.field.structure.type;
    },
    // ????
    selectedBlueprintId() {
      if (this.fieldStructureType !== 'reference') {
        return 'not_reference';
      }

      const field = FormBuilderGetters.getFieldInput(this.fieldId);
      return field?.structure?.ruleset?.blueprintId;
    },
  },
  async mounted() {
    await this.setRequiredData();
    await this.getAppSubtenants();
    this.selectedFieldType = this.fieldStructureType;

    if (this.condition) {
      this.subtenantSlug = this.condition.subtenantSlug;
      this.inputValue = this.condition.expectedValue;
      await this.getReferenceInstanceOptions();
    }
  },
  watch: {
    async fieldId() {
      this.inputValue = '';
      await this.setRequiredData();
      await this.getReferenceInstanceOptions();
      this.emitSelection();
    },
  },
  methods: {
    async setRequiredData() {
      if (this.fieldId) {
        this.field = await fieldApi.getOne(this.fieldId);
      }
    },
    emitSelection() {
      this.$emit('expectedValue', {
        subtenantSlug: this.subtenantSlug ?? '',
        value: this.inputValue,
      });
    },

    async getAppSubtenants() {
      let subtenants = TenantsGetters.getAllSubtenants(); // pentru blueprint Global sa arate toti subtenatii si sa nu crape
      if (subtenants.length === 0) {
        subtenants = await subtenantApi.getAll();
      }
      this.subtenantOptions = subtenants.map(function (s) {
        return {
          label: s.name,
          value: s.slug,
        };
      });
    },

    async getReferenceInstanceOptions() {
      if (this.field && this.fieldStructureType === 'reference') {
        this.referenceOptionsLoading = true;
        this.referenceOptions = [];
        console.log(this.field);
        const referencedBpId = this.field.structure?.ruleset?.blueprintId ?? null;
        if (referencedBpId) {
          let { instanceList } = await instanceApi.getGlobalInstances(referencedBpId);
          if (!instanceList.length) {
            const params = {};

            if (this.field.displayFieldsTokens && this.field.displayFieldsTokens !== '') {
              params.displayFieldTokens = this.field._id;
            }

            instanceList = await instanceApi.getAll(referencedBpId, params, []);
            console.log(instanceList);
          }
          const bp = await blueprintApi.getOne(referencedBpId);
          if (instanceList && bp) {
            const bpDisplayFieldId = bp.blueprint.displayFieldId;
            this.referenceOptions = instanceList.map(function (i) {
              return {
                value: i._id,
                label: i[bpDisplayFieldId], // trebuie scos display field pt a afisa textul default
              };
            });
          }
        }
        this.referenceOptionsLoading = false;
      }
    },
    filterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    emitInputValue() {
      this.$emit('update:modelValue', this.inputValue);
    },
    onBooleanValueSelect(value) {
      this.inputValue = value === 'true';
    },
  },
};
</script>

<style></style>
