<template>
  <div>
    <a-upload
      v-if="!viewingMode"
      :file-list="[]"
      :before-upload="beforeUpload"
      :multiple="true"
      :disabled="readOnly || (field.isReadOnlyAdd && addNew) || (field.isReadOnlyEdit && !addNew) || firstLoadActive"
    >
      <a-button>
        <UploadOutlined />
        upload
      </a-button>
    </a-upload>

    <a-list size="small" bordered v-if="previousFiles" :data-source="previousFiles" class="mt-1">
      <img :src="beforeUpload" alt="" />
      <template #renderItem="item">
        <a-list-item>
          <div>
            <PaperClipOutlined /><a :href="getFileDownloadLink(item)" target="_blank">{{ item.name }} </a>
          </div>
        </a-list-item>
      </template>
      <template #header>
        <strong>Current files</strong>
      </template>
    </a-list>

    <div class="fileListContainer">
      <div
        class="inputFile"
        :class="{
          uploaded: file.onServer,
          toBeDeleted: file.markedForDeletion,
        }"
        v-for="(file, i) in allFiles"
        :key="i"
      >
        <div v-if="file.onServer">
          <div
            v-if="imageTypes.includes(file.type) && structureOptions?.allowImagePopup"
            class="imageName"
            @click="imageModalRenderer(urlByName[file.name])"
          >
            {{ file.name }}
          </div>
          <div class="flexGrowEl" v-else>
            {{ file.name }}
          </div>
          <div
            v-if="imageTypes.includes(file.type) && structureOptions?.showImages"
            @click="imageModalRenderer(urlByName[file.name])"
            class="fieldImageContainer"
          >
            <img
              v-if="urlByName[file.name]"
              :src="urlByName[file.name]"
              @click="imageModalRenderer(urlByName[file.name])"
              style="cursor: pointer"
              alt=""
            />
          </div>
          <div class="mt-1">
            <a :href="getFileDownloadLink(file)" v-if="!structureOptions?.hideDownloadLink">Download</a>
            <a-tooltip title="Mark file for deletion" class="ml-3">
              <a-checkbox v-model:checked="file.markedForDeletion" style="float: right" v-if="!viewingMode"></a-checkbox>
            </a-tooltip>

            <audio-player
              v-if="structureOptions.showAudioPlayer"
              :src="getFileDownloadLink(file)"
              :type="file.type"
              :audioTrackPlay="audioTrackPlay"
            ></audio-player>
          </div>
        </div>

        <div v-else>
          <div
            v-if="imageTypes.includes(file.type) && structureOptions?.allowImagePopup"
            class="imageName"
            @click="imageModalRenderer(storeUploadedFiles[file.name])"
          >
            {{ file.name }}
          </div>
          <div class="flexGrowEl" v-else>
            {{ file.name }}
          </div>
          <div
            v-if="imageTypes.includes(file.type) && structureOptions?.showImages"
            @click="imageModalRenderer(storeUploadedFiles[file.name])"
            class="fieldImageContainer"
          >
            <img :src="storeUploadedFiles[file.name]" style="cursor: pointer" alt="" />
          </div>
          <div class="mt-1">
            <a :href="storeUploadedFiles[file.name]" v-if="!structureOptions?.hideDownloadLink">Download</a>
            <a-tooltip title="Remove file">
              <a-button size="small" class="ml-3" @click="handleRemove(file)" style="float: right" v-if="!viewingMode">
                <DeleteOutlined />
              </a-button>
            </a-tooltip>

            <audio-player
              v-if="structureOptions.showAudioPlayer"
              :src="storeUploadedFiles[file.name]"
              :type="file.type"
              :audioTrackPlay="audioTrackPlay"
            ></audio-player>
          </div>
        </div>
      </div>
    </div>
    <a-modal v-model:open="modalVisible" :footer="null" width="75%">
      <img :src="clickedImageUrl" width="90%" height="90%" alt="" />
    </a-modal>
  </div>
</template>

<script>
import { isValidJson } from '@/core/utils/string-manipulation';
import { TenantsGetters } from '@tenants/shared/tenants.store';
import AudioPlayer from '@dataSystem/components/FieldWidget/Inputs/AudioPlayer/AudioPlayer.vue';
import { DeleteOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
// eslint-disable-next-line import/named
import { filePublicApi } from '@/apps/dataSystem/api';
import InputMixin from './InputMixin';

export default {
  components: {
    AudioPlayer,
    PaperClipOutlined,
    UploadOutlined,
    DeleteOutlined,
  },
  mixins: [InputMixin],
  props: ['structureOptions'],
  data() {
    return {
      allFiles: [],
      previousFiles: null,
      modalVisible: false,
      uploading: false,
      clickedImageUrl: null,
      imageTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp', 'image/svg+xml'],
      audioTrackPlay: null,
      storeUploadedFiles: [],
      urlByName: {},
    };
  },
  computed: {
    appId: TenantsGetters.getAppId,
  },
  watch: {
    value() {},
    inputValue: {
      handler() {},
      deep: true,
    },
  },
  async mounted() {
    await this.parseFieldFiles();
  },
  methods: {
    async getFileToRender(file) {
      if (this.imageTypes.includes(file.type)) {
        if (!this.structureOptions.formatBase64) {
          this.urlByName[file.name] = `${process.env.VUE_APP_BASE_URL}/public/data-system/file/${this.appId}/${file.uploadedName}/${file.name}`;
        }
        try {
          this.urlByName[file.name] = await filePublicApi.getOneBase64({
            appId: this.appId,
            file,
          });
        } catch {
          console.log('File not found !');
        }
      }
    },
    getFileDownloadLink(file) {
      return `${process.env.VUE_APP_BASE_URL}/public/data-system/file/${this.appId}/${file.uploadedName}/${file.name}`;
    },
    getUploadedFileLink(file) {
      return URL.createObjectURL(this.inputValue.fileObjects.find(fileObject => fileObject.name === file.name));
    },

    async parseFieldFiles() {
      // read previous uploaded files
      if ((this.value && isValidJson(this.value)) || (this.rawValue && isValidJson(this.rawValue))) {
        const previousFiles = this.value && isValidJson(this.value) ? JSON.parse(this.value) : JSON.parse(this.rawValue);

        if (Array.isArray(previousFiles)) {
          const promises = previousFiles.map(async file => {
            await this.getFileToRender(file);
            return {
              ...file,
              onServer: true,
              markedForDeletion: false,
            };
          });

          this.allFiles = await Promise.all(promises);
        }

        this.inputValue = {
          type: 'file',
          fileObjects: [],
          fileData: this.allFiles,
        };
      } else {
        this.inputValue = {
          type: 'file',
          fileObjects: [],
          fileData: [],
        };
      }
    },

    handleRemove(file) {
      const { name, size, lastModified } = file;
      this.allFiles = this.allFiles.filter(f => f.onServer || !(f.name === name && f.size === size && f.lastModified === lastModified));
      this.inputValue.fileObjects = this.inputValue.fileObjects.filter(f => !(f.name === name && f.size === size && f.lastModified === lastModified));
      this.inputValue.fileData = this.allFiles;
    },
    beforeUpload(file) {
      if (!this.inputValue.fileObjects.find(f => f.name === file.name && f.size === file.size && f.lastModified === file.lastModified)) {
        this.allFiles.unshift({
          name: file.name,
          size: file.size,
          type: file.type,
          lastModified: file.lastModified,
          onServer: false,
        });

        this.inputValue.fileObjects.push(file);
        this.inputValue.fileData = this.allFiles;
        this.storeUploadedFiles[file.name] = this.getUploadedFileLink(file);
      } else {
        message.warning(`${file.name} has already been selected`);
      }
      return false;
    },
    imageModalRenderer(url) {
      if (this.structureOptions.allowImagePopup) {
        this.clickedImageUrl = url;
        this.modalVisible = true;
      }
    },
  },
};
</script>

<style @scoped>
.fileListContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.fileListContainer .inputFile {
  border: 1px solid #bebebe;
  border-radius: 4px;
  padding: 15px 10px;
  margin: 3px 5px;
  display: flex;
  flex-direction: column;
}
.fileListContainer .inputFile > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.fileListContainer .flexGrowEl {
  flex-grow: 1;
}
.fileListContainer .inputFile.uploaded {
  border-color: #1aa967;
}
.fileListContainer .inputFile.toBeDeleted {
  border-color: #d30000;
}
.fileListContainer .fieldImageContainer {
  display: flex;
  justify-content: center;
}
.fileListContainer .fieldImageContainer img {
  height: 100px;
  width: auto;
}
.imageName {
  cursor: default;
}
.imageName:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
