<template>
  <div>
    <ElementDataListView :loadMoreView="false" :lastDepth="lastDepth" :object="object" v-if="object?.view?.type === 'list'" />
    <ElementDataItemView
      v-else-if="object?.view?.type === 'item' || object?.view?.type === 'html'"
      :lastDepth="lastDepth"
      :object="object"
      :viewType="viewType"
      :instanceId="localInstanceId"
    />
    <KanbanBoard :loadMoreView="false" :lastDepth="lastDepth" :object="object" v-if="object?.view?.type === 'kanban'" />
    <GoogleCharts :loadMoreView="false" :lastDepth="lastDepth" :object="object" v-if="object?.view?.type === 'chart'" />
  </div>
</template>

<script>
import ElementDataListView from './ElementDataListView.vue';
import ElementDataItemView from './ElementDataItemView.vue';
import KanbanBoard from './KanbanBoard/KanbanBoard.vue';
import GoogleCharts from './GoogleCharts/GoogleCharts.vue';

export default {
  name: 'ElementData',
  props: ['object', 'viewType', 'instanceId', 'lastDepth'],
  components: {
    ElementDataListView,
    ElementDataItemView,
    KanbanBoard,
    GoogleCharts,
  },
  data() {
    return {
      localInstanceId: this.$route.params.instanceId ?? null,
    };
  },
  watch: {
    instanceId(value) {
      this.localInstanceId = value;
    },
    '$route.params.instanceId': function (value) {
      this.localInstanceId = value;
    },
  },
  async created() {
    if (this.instanceId) {
      this.localInstanceId = this.instanceId;
    }
  },
  methods: {},
};
</script>
<style scoped></style>
