import axios from 'axios';

import { TenantsGetters } from '@tenants/shared/tenants.store';
import { SlugGetters } from '@/slug.store';

const { getAppUrlWithGlobal } = SlugGetters;

const getAllGlobal = async () => {
  const response = await axios.get(`/global/global/data-system/blueprints`);
  return response.data;
};

const getAll = async () => {
  const response = await axios.get(`${getAppUrlWithGlobal(false)}/data-system/blueprints`);
  return response.data;
};

const getAllNames = async () => {
  const response = await axios.get(`${getAppUrlWithGlobal()}/data-system/blueprints-names`);
  return response.data;
};

const getAllWithFields = async (ids = []) => {
  const subtenant = TenantsGetters.getSubtenantBySlugs(SlugGetters.getTenantSlug(), SlugGetters.getAppSlug(), SlugGetters.getSubtenantSlug());

  const response = await axios.post(`${getAppUrlWithGlobal(false)}/data-system/blueprints-fields`, { ids, subtenantId: subtenant._id });
  return response.data;
};

const getOne = async blueprintId => {
  const response = await axios.get(`${getAppUrlWithGlobal(false)}/data-system/blueprints/${blueprintId}`);
  return response.data;
};

const getOneWithFields = async blueprintId => {
  const response = await axios.get(`${getAppUrlWithGlobal(false)}/data-system/blueprints/${blueprintId}`);
  return response.data;
};

const postOne = async blueprintInput => {
  const response = await axios.post(`${getAppUrlWithGlobal()}/data-system/blueprints`, blueprintInput);
  return response.data;
};

const patchOne = async (blueprintId, blueprintInput) => {
  const response = await axios.patch(`${getAppUrlWithGlobal()}/data-system/blueprints/${blueprintId}`, blueprintInput);
  return response.data;
};

const deleteOne = async blueprintId => {
  const response = await axios.delete(`${getAppUrlWithGlobal()}/data-system/blueprints/${blueprintId}`);
  return response.data;
};

const patchLinkSharing = async (blueprintId, isEnabled) => {
  const response = await axios.patch(`${getAppUrlWithGlobal()}/data-system/blueprints/${blueprintId}/link-sharing`, { isEnabled });
  return response.data;
};

const patchUniqueConstraints = async (blueprintId, uniqueConstraints) => {
  const response = await axios.patch(`${getAppUrlWithGlobal()}/data-system/blueprints/${blueprintId}/unique-constraints`, { uniqueConstraints });
  return response.data;
};

const postLinkSharingMail = async (blueprintId, recipientEmailList) => {
  const subtenant = TenantsGetters.getSubtenantBySlugs(SlugGetters.getTenantSlug(), SlugGetters.getAppSlug(), SlugGetters.getSubtenantSlug());
  if (!subtenant) {
    return;
  }
  await axios.post(`${getAppUrlWithGlobal()}/data-system/blueprints/${blueprintId}/link-sharing-mail?subtenantId=${subtenant._id}`, {
    recipientEmailList,
  });
};

const massEmail = async (blueprintId, email) => {
  const subtenant = TenantsGetters.getSubtenantBySlugs(SlugGetters.getTenantSlug(), SlugGetters.getAppSlug(), SlugGetters.getSubtenantSlug());
  if (!subtenant) {
    return;
  }

  await axios.post(`${getAppUrlWithGlobal(false)}/data-system/blueprints/${blueprintId}/massEmail?subtenantId=${subtenant._id}`, email, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export default {
  getAllGlobal,
  getAll,
  getAllNames,
  getAllWithFields,
  getOne,
  getOneWithFields,
  postOne,
  patchOne,
  deleteOne,
  patchLinkSharing,
  postLinkSharingMail,
  patchUniqueConstraints,
  massEmail,
};
