<template>
  <div v-if="!viewingMode">
    <div v-if="field">
      <Editor
        v-model="inputValue"
        :readOnly="readOnly || (field.isReadOnlyAdd && addNew) || (field.isReadOnlyEdit && !addNew) || firstLoadActive"
        :maxLength="structureOptions.maxLength"
        :placeholder="inputPlaceholder"
        :size="inputSize"
        :auto-focus="autoFocus"
        :autoSize="true"
        :required="field.isRequired"
        @blur="e => $emit('blur', e)"
        @pressEnter="e => $emit('pressEnter', e)"
        :init="init"
      />
    </div>
    <div v-else-if="init">
      <Editor v-model="inputValue" :init="init" />
    </div>
  </div>

  <div v-else class="textEditorViewContainer" v-html="viewingModeHtml"></div>
</template>

<script>
// TinyMCE
import 'tinymce/tinymce';

import 'tinymce/icons/default/icons';
import 'tinymce/themes/silver/theme';
import 'tinymce/models/dom/model';
import 'tinymce/skins/ui/oxide/skin.css';
// import contentUiCss from 'tinymce/skins/ui/oxide/content.css';

import 'tinymce/plugins/accordion/plugin';
import 'tinymce/plugins/advlist/plugin';
import 'tinymce/plugins/anchor/plugin';
import 'tinymce/plugins/autolink/plugin';
import 'tinymce/plugins/autoresize/plugin';
import 'tinymce/plugins/autosave/plugin';
import 'tinymce/plugins/charmap/plugin';
import 'tinymce/plugins/codesample/plugin';
import 'tinymce/plugins/directionality/plugin';
import 'tinymce/plugins/emoticons/plugin'; // css eror
import 'tinymce/plugins/fullscreen/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/image/plugin';
import 'tinymce/plugins/importcss/plugin';
import 'tinymce/plugins/insertdatetime/plugin';
import 'tinymce/plugins/media/plugin';
import 'tinymce/plugins/nonbreaking/plugin';
import 'tinymce/plugins/pagebreak/plugin';
import 'tinymce/plugins/preview/plugin';
import 'tinymce/plugins/quickbars/plugin';
import 'tinymce/plugins/save/plugin';
import 'tinymce/plugins/searchreplace/plugin';
import 'tinymce/plugins/visualblocks/plugin';
import 'tinymce/plugins/visualchars/plugin';
import 'tinymce/plugins/table/plugin';
import 'tinymce/plugins/code/plugin';
// import 'tinymce/plugins/help/plugin'        // css eroror
import 'tinymce/plugins/wordcount/plugin';

import Editor from '@tinymce/tinymce-vue';
import InputMixin from './InputMixin';

export default {
  mixins: [InputMixin],
  components: {
    Editor,
  },
  props: ['height'],
  data() {
    return {
      autosave: false,
      timer: null,
      autosaveDelay: 3 * 1000,
      init: {
        license_key: 'gpl',
        skin: false,
        promotion: false,
        statusbar: true,
        branding: false,
        width: null,
        height: 400,
        ui: {
          useAutoToolbar: false,
        },
        plugins:
          'lists link image table code  wordcount accordion advlist anchor autolink charmap codesample directionality  fullscreen importcss insertdatetime media nonbreaking pagebreak preview quickbars save searchreplace visualblocks visualchars ',
        toolbar:
          'fontselect | fontsizeselect | formatselect | bold italic underline strikethrough forecolor backcolor | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link table removeformat code',
        content_css: false,
        file_picker_types: 'file image media',
        file_picker_callback() {},
      },
    };
  },
  computed: {
    viewingModeHtml() {
      if (typeof this.rawValue === 'string') {
        const parser = new DOMParser();
        const doc = parser.parseFromString(this.rawValue, 'text/html');

        const tables = doc.querySelectorAll('table');

        tables.forEach(table => {
          // Check if the table is a base table (i.e., its parent is not another table)
          let isNestedTable = false;
          let parent = table.parentElement;

          while (parent) {
            if (parent.tagName.toLowerCase() === 'table') {
              isNestedTable = true;
              break;
            }
            parent = parent.parentElement;
          }

          if (!isNestedTable) {
            const wrapper = document.createElement('div');
            wrapper.className = 'tableScrollingContainer';
            table.parentNode.insertBefore(wrapper, table);
            wrapper.appendChild(table);
          }
        });

        return doc.body.innerHTML;
      }
      return '';
    },
  },
  mounted() {
    if (this.field) {
      if (this?.structureOptions?.defaultValue && !this.inputValue) {
        this.inputValue = this?.structureOptions?.defaultValue;
      }
      this.emitInput();
    }
  },
  created() {
    if (this.field) {
      this.init.width = this.structureOptions.width;
      this.init.height = this.structureOptions.height;
    } else {
      this.init.width = null;
      this.init.height = 200;
    }
  },
};
</script>
